import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  redirectToReport() {
    window.open('https://www.keylobby.com/report', '_blank');
    //location.href = 'https://www.keylobby.com/report';
  }

  scrollToSection() {
    var element = document.getElementById("about-content-section");
    element.scrollIntoView();
    element.scrollIntoView(false);
    element.scrollIntoView({block: "start"});
    element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }
  
}
