import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-why-short-term',
  templateUrl: './why-short-term.component.html',
  styleUrls: ['./why-short-term.component.scss']
})
export class WhyShortTermComponent implements OnInit {
  shortTermData = [];
  constructor() { }

  ngOnInit() {
  }

  redirectToReport() {
    window.open('https://www.keylobby.com/report', '_blank');
    //location.href = 'https://www.keylobby.com/report';
  }

}
