import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormEmailService } from '../../services/form-email.service';
import { EMAIL } from 'src/app/shared/constants/email';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
    contactUsForm : FormGroup;
    submitted = false;
    IsSending = false;
    constructor( 
        private formBuilder: FormBuilder,
        private formEmailService: FormEmailService
    ) { }

    ngOnInit() {
      this.contactUsForm = this.formBuilder.group({ 
        name: ['', Validators.required],
        email: ['', [Validators.required, Validators.pattern(EMAIL.EMAIL_REGEX)]],
        phone: ['', Validators.required],
        message: ['', Validators.required]
      });
    }

    onChange(e) {
      this.submitted = false;
    }

    get controls() {
      return this.contactUsForm.controls;
    }

    submit() {
        if (this.contactUsForm.invalid) {
            return;
        }
        this.submitted = false;
        this.IsSending = true;
        this.setContactUs(this.contactUsForm);
    }

    setContactUs(props) {
      const info = Object.assign({}, props.value);
      this.formEmailService.setContactUs(info).subscribe(res => {
         this.contactUsForm.reset();
         this.IsSending = false;
         this.submitted = true;
        });
    }

}
