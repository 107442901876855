import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AboutUsComponent } from './modules/home/about-us/about-us.component';
import { OurTeamComponent } from './modules/home/our-team/our-team.component';
import { ContactUsComponent } from './modules/home/contact-us/contact-us.component';
import { HomeComponent } from './modules/home/home.component';
import { WhyShortTermComponent } from './modules/home/why-short-term/why-short-term.component';
import { WhatWeDoNextComponent } from './modules/home/what-we-do-next/what-we-do-next.component';
import { PropertiesComponent } from './modules/home/properties/properties.component';
import { WeAreConnectedComponent } from './modules/home/we-are-connected/we-are-connected.component';
import { HowWeMaximizeRevenueComponent } from './modules/home/how-we-maximize-revenue/how-we-maximize-revenue.component';
import { ChoosingTheRightPropertyComponent } from './modules/home/choosing-the-right-property/choosing-the-right-property.component';


const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home',  component: HomeComponent },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'our-team', component: OurTeamComponent },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'why-short-term', component: WhyShortTermComponent },
  { path: 'what-we-do-next', component: WhatWeDoNextComponent},
  { path: 'properties', component: PropertiesComponent},
  { path: 'we-are-connected', component: WeAreConnectedComponent },
  { path: 'how-we-maximize-revenue', component: HowWeMaximizeRevenueComponent},
  { path: 'choosing-the-right-property', component: ChoosingTheRightPropertyComponent},
  { path: '**', component: HomeComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload',
    useHash: true
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export const routingComponents = [ HomeComponent, AboutUsComponent, OurTeamComponent, ContactUsComponent, 
  WhyShortTermComponent, WhatWeDoNextComponent, PropertiesComponent, WeAreConnectedComponent, HowWeMaximizeRevenueComponent, ChoosingTheRightPropertyComponent]

