import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-what-we-do-next',
  templateUrl: './what-we-do-next.component.html',
  styleUrls: ['./what-we-do-next.component.scss']
})
export class WhatWeDoNextComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  redirect() {
    window.open('https://www.keylobby.com/report', '_blank');
  }

}
