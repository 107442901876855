import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-properties',
  templateUrl: './properties.component.html',
  styleUrls: ['./properties.component.scss']
})
export class PropertiesComponent implements OnInit {

  properties = [
    {
      id: 1,
      name: 'Central Taupo Apartment',
      rating: 100,
      locationId: 1,
      locationName: "New Zealand",
      locationSpecificId: 1,
      locationSpecificName: "Taupo",
      bedRoomCount: 3,
      bathRoomCount: 1.5,
      details: "",
      img: "/assets/properties/1@2x.jpg",
      href: "https://keylobby.holidayfuture.com/listings/63527"
    },
    {
      id: 2,
      name: 'Awesome Central Wellington Apartment',
      rating: 80,
      locationId: 1,
      locationName: "New Zealand",
      locationSpecificId: 2,
      locationSpecificName: "Wellington",
      bedRoomCount: 2,
      bathRoomCount: 1,
      details: "",
      img: "/assets/properties/2@2x.jpg",
      href: "https://keylobby.holidayfuture.com/listings/64134"
    },
    {
      id: 3,
      name: 'Awesome Modern Rotorua House',
      rating: 89,
      locationId: 1,
      locationName: "New Zealand",
      locationSpecificId: 3,
      locationSpecificName: "Rotorua",
      bedRoomCount: 3,
      bathRoomCount: 1,
      details: "",
      img: "/assets/properties/3@2x.jpg",
      href: "https://keylobby.holidayfuture.com/listings/64001"
    },
    {
      id: 4,
      name: 'Awesome 2 Bed Wellington',
      rating: 100,
      locationId: 1,
      locationName: "New Zealand",
      locationSpecificId: 2,
      locationSpecificName: "Wellington",
      bedRoomCount: 2,
      bathRoomCount: 1,
      details: "",
      img: "/assets/properties/4@2x.jpg",
      href: "https://keylobby.holidayfuture.com/listings/64132"
    },
    {
      id: 5,
      name: 'Palmetto Palms',
      rating: 100,
      locationId: 2,
      locationName: "The Bahamas",
      locationSpecificId: 6,
      locationSpecificName: "Exuma",
      bedRoomCount: 3,
      bathRoomCount: 1.5,
      details: "",
      img: "/assets/properties/5@2x.jpg",
      href: "https://keylobby.holidayfuture.com/listings/61869"
    },
    {
      id: 6,
      name: 'Awesome 2 Bed Cuba Apartment',
      rating: 100,
      locationId: 1,
      locationName: "New Zealand",
      locationSpecificId: 2,
      locationSpecificName: "Wellington",
      bedRoomCount: 3,
      bathRoomCount: 1,
      details: "",
      img: "/assets/properties/6@2x.jpg",
      href: "https://keylobby.holidayfuture.com/listings/63355"

    },
    {
      id: 7,
      name: 'Awesome Central Christchurch Townhouse',
      rating: 100,
      locationId: 1,
      locationName: "New Zealand",
      locationSpecificId: 4,
      locationSpecificName: "Christchurch",
      bedRoomCount: 3,
      bathRoomCount: 1,
      details: "",
      img: "/assets/properties/7@2x.jpg",
      href:"https://keylobby.holidayfuture.com/listings/64136"
    },
    {
      id: 8,
      name: 'Lake Rotorua Bach',
      rating: 100,
      locationId: 1,
      locationName: "New Zealand",
      locationSpecificId: 3,
      locationSpecificName: "Rotorua",
      bedRoomCount: 3,
      bathRoomCount: 2,
      details: "",
      img: "/assets/properties/8@2x.jpg",
      href: "https://keylobby.holidayfuture.com/listings/64155"
    },
    {
      id: 9,
      name: 'Best Location in Wellington',
      rating: 100,
      locationId: 1,
      locationName: "New Zealand",
      locationSpecificId: 2,
      locationSpecificName: "Wellington",
      bedRoomCount: 2,
      bathRoomCount: 1,
      details: "",
      img: "/assets/properties/9@2x.jpg",
      href: "https://keylobby.holidayfuture.com/listings/64156"
    },
    {
      id: 10,
      name: 'Awesome Central Rotorua Pad',
      rating: 100,
      locationId: 1,
      locationName: "New Zealand",
      locationSpecificId: 3,
      locationSpecificName: "Rotorua",
      bedRoomCount: 2,
      bathRoomCount: 2,
      details: "",
      img: "/assets/properties/10@2x.jpg",
      href: "https://keylobby.holidayfuture.com/listings/63412"
    },
    {
      id: 11,
      name: 'Awesome 4 Beach House',
      rating: 100,
      locationId: 1,
      locationName: "New Zealand",
      locationSpecificId: 5,
      locationSpecificName: "Papamoa",
      bedRoomCount: 4,
      bathRoomCount: 1.5,
      details: "",
      img: "/assets/properties/11@2x.jpg",
      href: "https://keylobby.holidayfuture.com/listings/63953"
    },
    {
      id: 12,
      name: 'Awesome Christchurch Apartment',
      rating: 100,
      locationId: 1,
      locationName: "New Zealand",
      locationSpecificId: 4,
      locationSpecificName: "Christchurch",
      bedRoomCount: 2,
      bathRoomCount: 1,
      details: "",
      img: "/assets/properties/12@2x.jpg",
      href: "https://keylobby.holidayfuture.com/listings/64131"
    },
    {
      id: 13,
      name: 'Awesome 3 Bed Rotorua Lake House',
      rating: 100,
      locationId: 1,
      locationName: "New Zealand",
      locationSpecificId: 3,
      locationSpecificName: "Rotorua",
      bedRoomCount: 3,
      bathRoomCount: 1,
      details: "",
      img: "/assets/properties/13@2x.jpg",
      href: "https://keylobby.holidayfuture.com/listings/63955"
    },
    {
      id: 15,
      name: 'Central Christchurch Cottage',
      rating: 100,
      locationId: 1,
      locationName: "New Zealand",
      locationSpecificId: 4,
      locationSpecificName: "Christchurch",
      bedRoomCount: 2,
      bathRoomCount: 1,
      details: "",
      img: "/assets/properties/15@2x.jpg",
      href: "https://keylobby.holidayfuture.com/listings/64003"
    },
    {
      id: 16,
      name: 'Central Wellington 1 Bed Apartment',
      rating: 100,
      locationId: 1,
      locationName: "New Zealand",
      locationSpecificId: 2,
      locationSpecificName: "Wellington",
      bedRoomCount: 1,
      bathRoomCount: 1,
      details: "",
      img: "/assets/properties/16@2x.jpg",
      href: "https://keylobby.holidayfuture.com/listings/66261"
    },
  ]

  propertiesList = [];
  locations = [];
  selectedLocation;

  constructor(private _sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.locations = [
      {
        locationSpecificId: 0,
        locationSpecificName: "All Location"
      },
      {
        locationSpecificId: 1,
        locationSpecificName: "Taupo"
      },
      {
        locationSpecificId: 2,
        locationSpecificName: "Wellington"
      },
      {
        locationSpecificId: 3,
        locationSpecificName: "Rotorua"
      },
      {
        locationSpecificId: 4,
        locationSpecificName: "Christchurch"
      },
      {
        locationSpecificId: 5,
        locationSpecificName: "Papamoa"
      },
      {
        locationSpecificId: 6,
        locationSpecificName: "Exuma"
      }
    ]

    this.selectedLocation = this.locations[0];

    this.propertiesList = this.properties;
  }

  getBackgroundImage(image) {
    return this._sanitizer.bypassSecurityTrustStyle(`url(${image})`);
  }

  selLoc (e) {
    this.propertiesList = [];
    
    if(e.locationSpecificId == 0) {
      this.propertiesList = this.properties;
    } else {
      for(var i in this.properties) {
        if(this.properties[i].locationSpecificId == e.locationSpecificId) {
          this.propertiesList.push(this.properties[i]);
        }
      }
    }
  }
  
  redirect(href) {
    window.open(href, '_blank');
  }

  redirectKeylobby() {
    window.open('https://keylobby.holidayfuture.com/', '_blank');
  }

}
