import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-we-are-connected',
  templateUrl: './we-are-connected.component.html',
  styleUrls: ['./we-are-connected.component.scss']
})
export class WeAreConnectedComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
