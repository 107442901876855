import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-choosing-the-right-property',
  templateUrl: './choosing-the-right-property.component.html',
  styleUrls: ['./choosing-the-right-property.component.scss']
})
export class ChoosingTheRightPropertyComponent implements OnInit {
  expandLocation = false;
  expandLayout = false;
  expandAmenities = false;
  expandLookAndFeel = false;
  expandType = false;

  constructor() { }

  ngOnInit() {
  } 

  redirectToReport() {
    window.open('https://www.keylobby.com/report', '_blank');
  }
}
