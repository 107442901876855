import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Keylobby-UI';
  public showMenu:boolean = false;
  
  toggle() { this.showMenu = !this.showMenu;}

  redirectToFB() {
    window.open('https://www.facebook.com/keylobbyPM', '_blank');
    //location.href = 'https://www.facebook.com/keylobbyPM';
  }
  redirectToIG() {
    window.open('https://www.instagram.com/keylobby', '_blank');
  }
  
  onActivate(event) {
    window.scroll(0, 0);
  }
}
