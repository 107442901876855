import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  redirectToReport() {
    window.open('https://www.keylobby.com/report', '_blank');
    //location.href = 'https://www.keylobby.com/report';
  }

  scrollToSection() {
    var element = document.getElementById("market-insights-section");
    element.scrollIntoView();
    element.scrollIntoView(false);
    element.scrollIntoView({block: "start"});
    element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }

}
