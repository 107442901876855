import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FormEmailService {
   constructor(private _http: HttpClient) {}
  baseUrl = environment.apiUrl;
  setContactUs(props) {
   // console.log(`${this.baseUrl}/email/contact-us`, {name:props.name, email: props.email, phone: props.phone, message: props.message});
     return this._http.post(`${this.baseUrl}/email/contact-us`, {name:props.name, email: props.email, phone: props.phone, message: props.message});
  }
}